import logo from "@/assets/logo.svg";

import { usePage, Link } from "@inertiajs/react";
import SharedPageProps from "@/types/SharedPageProps";

const InternalServerErrorPage = ({ user, t }: any) => {
  const {
    paths: { root_path },
  } = usePage<SharedPageProps>().props;

  return (
    <>
      <div className="mx-8 flex h-fit flex-col">
        <div className="mb-4 flex w-full items-center justify-center gap-2">
          <h1 className="text-9xl font-extrabold">5</h1>

          <Link href={root_path} className="space-x-2">
            <img
              src={logo}
              alt="logo"
              className="inline-block w-28 hover:animate-spin"
            />
            <img
              src={logo}
              alt="logo"
              className="inline-block w-28 hover:animate-spin"
            />
          </Link>
        </div>

        <div className="mb-4 flex w-full flex-col">
          <h1 className="text-2xl font-bold">
            There was an internal server error.
          </h1>
          <p>
            Please try again later or contact support if the problem persists.
          </p>
        </div>
      </div>
    </>
  );
};

export default InternalServerErrorPage;
