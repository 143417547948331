import signature from "@/assets/sig.svg";

Make Live Photos 今天起开始公测！

经过长达一个月的 Alpha 内测。我们终于准备好了迎接更多的用户。

这一个月在大家的帮助下，我们修复了很多问题，也增加了很多实用的新功能。

当然，Beta 阶段仍然可能出现一些问题。如果碰到，欢迎随时联系我们。

- 😶‍🌫️ 使用教程还在写
- 🚀 速度优化还不到位
- 😭 服务器短暂下线
- 🐞 需要手动报 Bug
- 👀 …

Beta 阶段所有的参与者也会获得终身的额外福利。

1.  每个月免费的 10 张 Live Photos 配额。
2.  你在加入后也可以邀请其他人。
3.  Beta 阶段内，优先反馈通道。

感谢您的耐心等待，欢迎体验！

<div className="signature">
  <figure className="m-0">
    <img src={signature} alt="青城" width="206" height="74" />
  </figure>
  <dl className="m-0">
    <dt className="m-0 font-sans font-bold">青城</dt>
    <dd className="m-0 p-0">
      <a href="mailto:creator@makelive.photos">creator@makelive.photos</a>
    </dd>
  </dl>
</div>
