import { cn } from "@/lib/utils";
import {
  MonthlyQuota,
  PurchasedQuota,
  TranslationItem,
} from "@/types/SharedPageProps";

import logo from "@/assets/logo.svg";
import image from "@/assets/image.svg";
import video from "@/assets/video.svg";

import { filesize } from "filesize";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";

import { useMediaQuery } from "@/hooks/use-media-query";

import PricingModel from "@/contents/pricing-model.mdx";

type LivePhotoQuotaProps = {
  monthlyQuota: MonthlyQuota;
  purchasedQuota: PurchasedQuota;
  t: TranslationItem[];
  className?: string;
};

const LivePhotoQuota = ({
  monthlyQuota,
  purchasedQuota,
  className,
  t,
}: LivePhotoQuotaProps) => {
  // TODO: use programmatic way to retrive tailwind's breakpoints
  const isDesktop = useMediaQuery("(min-width: 768px)"); // md: 640px

  return (
    <div className={cn("relative text-start", className)}>
      <>
        {isDesktop ? (
          <Dialog>
            <DialogTrigger className="absolute -right-2 -top-2 z-10 flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-tr from-black to-slate-700 text-xs font-bold text-white ring-1 ring-black ring-offset-1">
              ?
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>定价策略</DialogTitle>
                <DialogDescription>
                  在正式发布之后，开始采用的定价策略。
                </DialogDescription>
              </DialogHeader>

              <ScrollArea className="prose h-72">
                <PricingModel />
              </ScrollArea>
            </DialogContent>
          </Dialog>
        ) : (
          <Drawer shouldScaleBackground>
            <DrawerTrigger asChild>
              <button className="absolute -right-2 -top-2 z-10 flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-tr from-black to-slate-700 text-xs font-bold text-white ring-1 ring-black ring-offset-1">
                ?
              </button>
            </DrawerTrigger>

            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>定价策略</DrawerTitle>
                <DrawerDescription>
                  在正式发布之后，开始采用的定价策略。
                </DrawerDescription>
              </DrawerHeader>

              <ScrollArea className="prose mx-4 mb-4 h-72">
                <PricingModel />
              </ScrollArea>

              <DrawerClose />
            </DrawerContent>
          </Drawer>
        )}
      </>

      <ScrollArea className="max-h-[12rem] rounded-md border p-4">
        <QuotaArea quota={monthlyQuota} t={t} />
        <hr className="my-4 border-dashed" />
        <QuotaArea quota={purchasedQuota} t={t} />
      </ScrollArea>
    </div>
  );
};

export default LivePhotoQuota;

type QuotaAreaProps = {
  quota: MonthlyQuota | PurchasedQuota;
  t: TranslationItem[];
};

const QuotaArea = ({ quota, t }: QuotaAreaProps) => {
  return (
    <>
      {quota ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-bold">
              {quota.quota_type === "monthly"
                ? "Monthly Quota"
                : "Purchased Quota"}
            </h2>
            <span className="text-xs text-gray-500">
              {quota.quota_type === "monthly" && (
                // <>将于 {new Date(quota?.expire_in).toLocaleString()} 重置</>
                <>
                  {t["Quota Reset Time"]}
                  {new Date(quota?.expire_in).toLocaleString()}
                </>
              )}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <img src={image} alt="image icon" className="stroke-black" />

              <HoverCard>
                <HoverCardTrigger className="cursor-help border-b border-dashed text-sm">
                  {filesize(quota?.each_image_up_to, { base: 2 })}
                </HoverCardTrigger>
                <HoverCardContent>
                  图片大小上限 {filesize(quota?.each_image_up_to, { base: 2 })}
                </HoverCardContent>
              </HoverCard>
            </div>

            <div className="flex flex-row items-center gap-2">
              <img src={video} alt="video icon" className="stroke-black" />

              <HoverCard>
                <HoverCardTrigger className="cursor-help border-b border-dashed text-sm">
                  {filesize(quota?.each_video_up_to, { base: 2 })}
                </HoverCardTrigger>
                <HoverCardContent>
                  视频大小上限 {filesize(quota?.each_video_up_to, { base: 2 })}
                </HoverCardContent>
              </HoverCard>
            </div>

            <div className="flex flex-row items-center gap-2">
              <img src={logo} alt="logo" className="h-5 w-5 fill-black" />
              <HoverCard>
                <HoverCardTrigger className="cursor-help border-b border-dashed text-sm">
                  {quota?.usage_left}
                </HoverCardTrigger>
                <HoverCardContent>
                  可以生成的 Live 图剩余 {quota?.usage_left} 张
                </HoverCardContent>
              </HoverCard>
            </div>
          </div>
        </div>
      ) : (
        <span className="text-sm text-gray-500">
          {t["No other quotas available"]}
        </span>
      )}
    </>
  );
};
