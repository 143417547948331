import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useForm } from "@inertiajs/react";

const EditableText = ({
  id,
  name,
  text_update_path,
  cancel_text,
  save_text,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    data: formData,
    setData: setFormData,
    patch,
    processing,
    errors,
  } = useForm({
    // it should use name as key
    // children is the value
    [name]: children,
  });

  const handleEdit = () => {
    console.log("Editing", formData[name]);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    // assert e.targe.name === name
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: children,
    }));

    setIsEditing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting", formData[name]);
    setIsEditing(false);

    patch(text_update_path, {
      preserveScroll: true,
      onSuccess: () => {
        console.log("Successfully updated", formData);
      },
    });
  };

  return (
    <div className="inline-edit-form">
      {isEditing ? (
        <form
          onSubmit={handleSubmit}
          className="relative flex w-full flex-col bg-background"
        >
          <input
            type="text"
            id={id}
            name={name}
            autoComplete="off"
            value={formData[name]}
            onChange={handleChange}
            onBlur={handleCancel}
            autoFocus
            required
            className="z-20 h-6 w-full border-b outline-none"
          />

          <div className="absolute inset-y-6 z-10 flex h-fit w-full flex-row justify-start space-x-2 bg-gradient-to-b from-background via-background via-10% to-transparent pt-2">
            <Button
              type="button" // need this to prevent form reconising this button as submit
              variant="ghost"
              disabled={processing}
              onClick={handleCancel}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {cancel_text || "Cancel"}
            </Button>

            <Button
              type="submit"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              disabled={processing}
              className="btn--black w-fit"
            >
              {save_text || "Save"}
            </Button>
          </div>
        </form>
      ) : (
        <div className="relative">
          <span onClick={handleEdit}>{formData[name]}</span>
          {errors[name] && (
            <>
              {formData[name] === "" && (
                <span onClick={handleEdit}>
                  <p className="text-red-500">{errors[name]}</p>
                </span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableText;
