import logo from "@/assets/logo.svg";

import { usePage, Link, useForm } from "@inertiajs/react";
import SharedPageProps from "@/types/SharedPageProps";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import ButtonLoadingState from "@/components/ButtonLoadingState";

const ChangePasswordForm = ({ user, t }: any) => {
  const {
    paths: { home_path, password_path },
  } = usePage<SharedPageProps>().props;

  const {
    data: formData,
    setData: setFormData,
    patch,
    processing,
    errors,
  } = useForm({
    password_challenge: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patch(password_path);
    // toast.warning(t["Registration Disabled"]);
  };

  return (
    <>
      <div className="flex h-fit max-w-md flex-col rounded-xl border shadow-lg">
        <div className="relative z-10 w-72 rounded-xl bg-white p-8 shadow-sm xs:w-[25rem]">
          <div className="mb-4 flex w-full items-center justify-center">
            <Link href={home_path}>
              <img src={logo} alt="logo" className="hover:animate-spin" />
            </Link>
          </div>

          <div className="mb-4 flex w-full flex-col">
            <h1 className="text-2xl font-bold">{t["Change your password"]}</h1>
            {/* <p>{t["Welcome to MakeLivePhotos"]}</p> */}
          </div>

          <form onSubmit={handleSubmit} className="text-start">
            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password_challenge">
                {t["Current password"]}
              </Label>
              <Input
                type="password"
                id="password_challenge"
                name="password_challenge"
                value={formData.password_challenge}
                onChange={handleChange}
                required
                autoFocus
                autoComplete="current-password"
                placeholder={t["Enter your current password"]}
                className={
                  errors.password_challenge &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password_challenge && (
                <div className="text-xs text-destructive">
                  {errors.password_challenge}
                </div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password">{t["New password"]}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password && (
                <div className="text-xs text-destructive">
                  {errors.password}
                </div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password_confirmation">
                {t["Confirm new password"]}
              </Label>
              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password_confirmation &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password_confirmation && (
                <div className="text-xs text-destructive">
                  {errors.password_confirmation}
                </div>
              )}
            </div>

            <div className="text-center">
              <Button
                type="submit"
                disabled={processing}
                className="btn--black"
              >
                <ButtonLoadingState processing={processing}>
                  {t["Save changes"]}
                </ButtonLoadingState>
              </Button>
            </div>
          </form>
        </div>

        <div className="-my-4 mb-0 rounded-xl bg-slate-100 pt-4">
          <div className="flex justify-between px-8 py-4">
            <div className="flex gap-1">
              <p className="text-sm text-slate-400">{t["Never mind"]}</p>
              <Link
                href={home_path}
                className="text-sm text-blue-500 hover:underline"
              >
                {t["Back home"]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordForm;
