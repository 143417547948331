import { useState } from "react";
import LivePhotosGalleryPortrait from "@/components/LivePhotosGalleryPortrait";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { RefreshCwIcon } from "lucide-react";
import { router } from "@inertiajs/react";

import { LivePhoto, TranslationItem } from "@/types/SharedPageProps";

type LivePhotosGalleryProps = {
  livePhotos: LivePhoto[];
  className?: string;
  t: TranslationItem[];
};

const LivePhotosGallery = ({
  livePhotos,
  className,
  t,
}: LivePhotosGalleryProps) => {
  const [refreshing, setRefreshing] = useState(false);

  // Define a function to handle refreshing the page
  const handleRefresh = (e) => {
    e.preventDefault();
    setRefreshing(true);
    console.log("Refreshing...");
    router.reload({
      only: ["live_photos"],
      onSuccess: () => {
        setRefreshing(false);
      },
    });
  };

  return (
    <div className={cn("text-start", className)}>
      <div className="mb-4 flex flex-row justify-between">
        <h1 className="text-2xl font-black">Live Photos Gallery</h1>

        <button
          className="flex flex-row items-center gap-2 hover:underline"
          onClick={handleRefresh}
        >
          <RefreshCwIcon
            className={cn(
              "h-4 w-4",
              refreshing && "animate-spin", // Apply the "animate-spin" class if the page is refreshing
            )}
          />
          {t["Refresh"]}
        </button>
      </div>
      <ScrollArea className="h-[24rem] rounded-md border p-4">
        <div className="flex flex-col gap-4">
          {livePhotos
            // sort the live photos by their update_at time, in reverse order
            .sort(
              (a, b) =>
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime(),
            )
            .map((livePhoto) => (
              <LivePhotosGalleryPortrait
                livePhoto={livePhoto}
                key={livePhoto.id}
                t={t}
              />
            ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default LivePhotosGallery;
