import logo from "@/assets/logo.svg";

import { usePage, Link } from "@inertiajs/react";
import SharedPageProps from "@/types/SharedPageProps";

const UnsupportedBrowserPage = ({ user, t }: any) => {
  const {
    paths: { root_path },
  } = usePage<SharedPageProps>().props;

  return (
    <>
      <div className="mx-8 flex h-fit flex-col">
        <div className="mb-4 flex w-full items-center justify-center gap-2">
          <h1 className="text-9xl font-extrabold">4</h1>

          <Link href={root_path}>
            <img src={logo} alt="logo" className="w-28 hover:animate-spin" />
          </Link>

          <h1 className="text-9xl font-extrabold">6</h1>
        </div>

        <div className="mb-4 flex w-full flex-col">
          <h1 className="text-2xl font-bold">
            Your browser is too old to view this website.
          </h1>
          <p>Please upgrade to a modern browser.</p>
        </div>
      </div>
    </>
  );
};

export default UnsupportedBrowserPage;
