import LivePhotoAlbum from "@/components/LivePhotoAlbum";

const Show = ({ live_photo }) => {
  return (
    <div>
      <h1>Show LivePhoto</h1>

      <div id="album" className="aspect-square h-96 w-96">
        <LivePhotoAlbum
          photoSrc={live_photo.image_url}
          videoSrc={live_photo.video_url}
        />
      </div>
    </div>
  );
};

export default Show;
