import logo from "@/assets/logo.svg";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import SharedPageProps from "@/types/SharedPageProps";

import { Link, usePage, useForm } from "@inertiajs/react";

const TriggerPasswordReset = ({ t }: any) => {
  const {
    paths: { root_path, sign_up_path, identity_password_reset_path },
  } = usePage<SharedPageProps>().props;

  const {
    data: formData,
    setData: setFormData,
    post,
    processing,
    errors,
  } = useForm({
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    post(identity_password_reset_path);
  };

  return (
    <>
      <div className="flex h-fit max-w-md flex-col rounded-xl border shadow-lg">
        <div className="relative z-10 w-72 rounded-xl bg-white p-8 shadow-sm xs:w-[25rem]">
          <div className="mb-4 flex w-full items-center justify-center">
            <Link href={root_path}>
              <img src={logo} alt="logo" className="hover:animate-spin" />
            </Link>
          </div>

          <div className="mb-4 flex w-full flex-col">
            <h1 className="text-2xl font-bold">{t["Forgot your password?"]}</h1>
            <p>{t.enter_below}</p>
          </div>

          <form onSubmit={handleSubmit} className="text-start">
            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                autoFocus
                autoComplete="email"
                placeholder={t["Email"]}
                disabled={processing}
                className=""
              />
              {errors.email && (
                <div className="text-red-500">{errors.email}</div>
              )}
            </div>

            <div>
              <Button className="btn--black">
                {t["Email me reset instructions"]}
              </Button>
            </div>
          </form>
        </div>

        <div className="-my-4 mb-0 w-72 rounded-xl bg-slate-100 pt-4 xs:w-[25rem]">
          <div className="flex justify-between px-8 py-4">
            <p className="w-fit text-wrap text-sm text-slate-400">
              {t.check_your_spam}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TriggerPasswordReset;
