Private Alpha 用户

- 永远免费：无限的动态照片配额。
- 图片大小上限：10MB。
- 视频大小上限：40MB。

Public Beta 用户

- 免费等级：每月10张动态照片配额（不累计）。
- 图片大小上限：2MB。
- 视频大小上限：8MB。

普通用户

- 免费等级：每月2张动态照片配额（不累计）。
- 图片大小上限：2MB。
- 视频大小上限：8MB。

付费配额（可累计，简单定价）

- 0.5 USD/EUR/GBP 或 1 CNY（基于地理位置的购买力平价）= 10 张动态照片。
- 图片大小上限：10MB。
- 视频大小上限：40MB。

服务水平协议（SLA）

- 增加大小限制（图片/视频）
  - Private Alpha 用户可以在不额外收费的情况下提出请求，但需逐案处理。
- 定制化配额折扣（> 1000）
- ...
