import LivePhotosCreator from "@/components/LivePhotosCreator";
import LivePhotosGallery from "@/components/LivePhotosGallery";
import LivePhotoQuota from "@/components/LivePhotosQuota";
import { TailwindIndicator } from "@/components/TailwindIndictor";
import { cn } from "@/lib/utils";

import key from "@/assets/key.svg";

import SharedPageProps from "@/types/SharedPageProps";

import { Link, usePage } from "@inertiajs/react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import Footer from "@/components/layouts/Footer";
import {
  KeyIcon,
  LogOut,
  LogOutIcon,
  LogsIcon,
  MailWarningIcon,
  MonitorSmartphoneIcon,
  QrCodeIcon,
  RectangleEllipsisIcon,
  SendIcon,
  ShieldEllipsisIcon,
  TicketIcon,
} from "lucide-react";

export default function Index({ t }) {
  const user_status = {
    normal: {
      name: "普通用户",
      style: "border-slate-300 bg-slate-100 text-slate-700",
    },
    private_alpha: {
      name: "Private Alpha 用户",
      style: "border-amber-300 bg-amber-100 text-amber-700",
    },
    public_beta: {
      name: "Public Beta 用户",
      style: "border-green-300 bg-green-100 text-green-700",
    },
    admin: {
      name: "Admin",
      style: "border-blue-300 bg-blue-100 text-blue-700",
    },
    super_admin: {
      name: "Super Admin",
      style: "border-red-300 bg-red-100 text-red-700",
    },
  };

  const { session, user, paths, live_photos } =
    usePage<SharedPageProps>().props;

  return (
    <>
      {/* <TailwindIndicator /> */}

      <div className="absolute inset-x-0 top-0 -z-10 h-[37.5rem] bg-top bg-grid-slate-900/[0.04] [mask-image:linear-gradient(0deg,transparent,black)] xl:top-8"></div>
      <div className="mx-8 my-16 grid auto-cols-max auto-rows-min items-center gap-8 md:my-32 md:max-w-4xl md:grid-cols-2 md:gap-16 md:gap-y-8">
        {/* Personal Greetings */}
        <div className="col-span-1 row-span-1 space-y-4 md:order-2">
          <div
            className={cn(
              "mx-auto w-fit rounded-full border px-4 py-1 text-sm",
              user_status[user.status].style,
            )}
          >
            {user_status[user.status].name}
          </div>

          <h1 className="max-w-sm text-3xl font-bold">
            {t["Welcome Back"]} 👋 {user.user_name}
          </h1>
          <h2 className="text-2xl font-medium">
            {t["Let's make some Live Photos"]}
          </h2>
        </div>

        <LivePhotoQuota
          className="order-4 col-span-1 row-span-1 md:order-3"
          monthlyQuota={user.monthly_quota}
          purchasedQuota={user.purchased_quota}
          t={t}
        />

        <LivePhotosCreator
          className="order-2 col-span-1 row-span-3 md:order-1"
          creatorDisabled={false}
          // t={t}
        />

        <LivePhotosGallery
          livePhotos={live_photos}
          className="order-3 row-span-1"
          t={t}
        />
      </div>

      {/* Control Centre Area - TODO: extract out the component */}
      <h2 className="text-4xl font-bold">{t["Control Centre"]}</h2>

      <div
        className="mx-8 my-8 grid max-w-sm gap-4 text-start md:max-w-4xl md:grid-cols-2"
        data-control-centre="area"
      >
        <div
          className="flex w-full flex-col items-center gap-4"
          data-control-centre="column"
        >
          <Card
            data-control-centre="island"
            className="w-full border-0 shadow-none"
          >
            <CardHeader data-control-centre="description">
              <CardTitle>{t["Sign-In and Security"]}</CardTitle>
              <CardDescription>
                {t["sign_in_security_description"]}
              </CardDescription>
            </CardHeader>

            <CardContent data-control-centre="cards" className="space-y-4">
              <Link
                href={`/sessions/${session.id}`}
                method="delete"
                className="flex w-full flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium text-destructive drop-shadow-configCard"
                as="button"
                type="button"
              >
                {t["Log Out"]}
                <LogOutIcon className="stroke-destructive" />
              </Link>

              <Link
                href={paths.edit_password_path}
                className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                data-control-centre="card"
              >
                {t["Change Password"]}
                <RectangleEllipsisIcon />
              </Link>

              <Link
                href={paths.edit_identity_email_path}
                className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
              >
                {t["Change Email"]}
                <MailWarningIcon />
              </Link>
            </CardContent>
          </Card>
        </div>

        <div
          className="flex w-full flex-col items-center gap-4"
          data-control-centre="column"
        >
          <Card
            data-control-centre="island"
            className="w-full border-0 shadow-none"
          >
            <CardHeader data-control-centre="description">
              <CardTitle>{t["Invitations"]}</CardTitle>
              <CardDescription>{t["invitations_description"]}</CardDescription>
            </CardHeader>

            <CardContent data-control-centre="cards" className="space-y-4">
              <Link
                href={paths.invitation_codes_path}
                className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                disabled={user.status != "admin"}
              >
                {t["Invitation Codes"]}
                <TicketIcon />
              </Link>
            </CardContent>
          </Card>

          {user.status == "super_admin" && (
            <Card
              data-control-centre="super-admin-island"
              className="w-full border-dashed shadow-none"
            >
              <CardHeader data-control-centre="description">
                <CardTitle className="flex items-center gap-2">
                  {/* <img src={key} alt="key" className="h-8 w-8" /> */}
                  <KeyIcon />
                  Admin Land
                </CardTitle>
                <CardDescription>
                  Manage settings related to the entire Live Photos platform.
                </CardDescription>
              </CardHeader>

              <CardContent data-control-centre="cards" className="space-y-4">
                <Link
                  href="#"
                  className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                  data-control-centre="card"
                >
                  Send invitation
                  <SendIcon />
                </Link>

                <Link
                  href="#"
                  className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                  data-control-centre="card"
                >
                  Two-Factor Authentication
                  <QrCodeIcon />
                </Link>

                <Link
                  href="#"
                  className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                  data-control-centre="card"
                >
                  Devices & Sessions
                  <MonitorSmartphoneIcon />
                </Link>

                <Link
                  href="#"
                  className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                  data-control-centre="card"
                >
                  Activity Log
                  <LogsIcon />
                </Link>

                <Link
                  href="admin/jobs"
                  className="flex flex-row items-center justify-between rounded-xl border bg-configCard p-4 font-medium drop-shadow-configCard"
                  data-control-centre="card"
                >
                  Mission Control for Solid Queue Jobs
                  <ShieldEllipsisIcon />
                </Link>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
