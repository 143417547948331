import AlphaUsersMarquee from "@/contents/AlphaUsersMarquee";

这个 App 是做给浪漫主义者 (Romantics) 的。

在这些浪漫主义者中，这个 App 是因为一些热爱生活的人——手帐 er 们——而诞生的。

因此我想这个小 App 离不开 Private Alpha 用户的支持。我想向这些用户表达由衷的感谢。

<AlphaUsersMarquee />

当然，在所有的浪漫主义的用户中，它是我这个还算有点浪漫主义的人，做给我心中独一无二的、不可磨灭的那道身影的。她在很多年前，蹦蹦跳跳地进入了我的生活。此后我从未见过一个笑起来如此可爱的人。

所以，最后还有两句话，是给她的。因为如果不是她，我绝对不会那么用心地去深入手帐这个圈子。我自然不会遇见那么多有意思的浪漫的同好。

第一句：

「萝卜青菜，各有所爱。」我同意。不过，我不爱「萝」、我不爱「卜」、我不爱「青」。

第二句：

I Love You More Than Sharks Love Blood.

🌈
