import LivePhotoAlbum from "@/components/LivePhotoAlbum";
import { Badge } from "@/components/ui/badge";
import EditableText from "@/components/EditableText";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

import { router, useForm } from "@inertiajs/react";
import {
  CloudDownloadIcon,
  EyeIcon,
  EyeOffIcon,
  Trash2Icon,
} from "lucide-react";
import { useEffect, useState } from "react";
import ButtonLoadingState from "@/components/ButtonLoadingState";
import { LivePhoto, TranslationItem } from "@/types/SharedPageProps";

type LivePhotosGalleryPortraitProps = {
  livePhoto: LivePhoto;
  t: TranslationItem[];
};

const LivePhotosGalleryPortrait = ({
  livePhoto,
  t,
}: LivePhotosGalleryPortraitProps) => {
  const {
    data,
    setData,
    delete: deleteLivePhoto,
    processing,
    errors,
  } = useForm({});

  const handleDelete = (e) => {
    e.preventDefault();

    deleteLivePhoto(`/live_photos/${livePhoto.id}`, {
      preserveScroll: true,
    });

    // router.delete(`/live_photos/${livePhoto.id}`, {
    //   preserveScroll: true,
    // });
  };

  const [showPreview, setShowPreview] = useState(false);

  const statusBadgeStyle = {
    pending: "border-yellow-600 text-yellow-600 text-nowrap",
    processing: "border-blue-600 text-blue-600 text-nowrap",
    processed: "border-green-600 text-green-600 text-nowrap",
    failed: "border-red-600 text-red-600 text-nowrap",
  };

  return (
    <Card className="w-[350px] bg-silver" data-card={`id-${livePhoto.id}`}>
      <CardHeader data-card="header">
        <CardTitle
          data-card="title"
          className="flex items-center justify-between"
        >
          <EditableText
            id="title"
            name="title"
            text_update_path={`/live_photos/${livePhoto.id}`}
            cancel_text={t["Cancel"]}
            save_text={t["Save"]}
          >
            {livePhoto.title}
          </EditableText>

          <Badge
            variant="outline"
            className={statusBadgeStyle[livePhoto.status]}
            // className="border-emerald-600 bg-gradient-to-t from-emerald-400 to-emerald-400 text-emerald-700"
          >
            {/* {livePhoto.status.toUpperCase()} */}
            {t[livePhoto.status]}
          </Badge>
        </CardTitle>

        <CardDescription
          data-card="description"
          className="flex flex-row items-center justify-between gap-4"
        >
          {livePhoto.status === "processed" && (
            <>
              <button
                className="flex flex-row items-center gap-2 hover:underline"
                onClick={() => setShowPreview(!showPreview)}
              >
                {showPreview ? (
                  <EyeIcon className="h-4 w-4" />
                ) : (
                  <EyeOffIcon className="h-4 w-4" />
                )}{" "}
                {t["Preview"]}
              </button>

              <a
                href={livePhoto.pvt_url}
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center gap-2 hover:underline"
              >
                <CloudDownloadIcon className="h-4 w-4" /> {t["Download"]}
              </a>
            </>
          )}

          <AlertDialog>
            <AlertDialogTrigger className="ml-auto flex flex-row items-center gap-2 text-destructive hover:underline">
              <Trash2Icon className="h-4 w-4" />
              {t["Delete"]}
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete
                  this live photo from our servers.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{t["Cancel"]}</AlertDialogCancel>
                <AlertDialogAction
                  className="btn--black sm:w-fit"
                  onClick={handleDelete}
                  disabled={processing}
                >
                  <ButtonLoadingState processing={processing}>
                    {t["Continue"]}
                  </ButtonLoadingState>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </CardDescription>
      </CardHeader>

      {showPreview && (
        <CardContent data-card="content">
          <div id="album" className="aspect-square w-full">
            <LivePhotoAlbum
              photoSrc={livePhoto.image_url}
              videoSrc={livePhoto.video_url}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default LivePhotosGalleryPortrait;
