import logo from "@/assets/logo.svg";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import SharedPageProps from "@/types/SharedPageProps";

import { Link, usePage, useForm } from "@inertiajs/react";

const EditPasswordReset = ({ t }: any) => {
  const {
    sid,
    paths: { root_path, identity_password_reset_path },
  } = usePage<SharedPageProps>().props;

  const {
    data: formData,
    setData: setFormData,
    patch,
    processing,
    errors,
  } = useForm({
    sid: sid,
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    patch(identity_password_reset_path);
  };

  return (
    <>
      <div className="flex h-fit max-w-md flex-col rounded-xl border shadow-lg">
        <div className="relative z-10 w-72 rounded-xl bg-white p-8 shadow-sm xs:w-[25rem]">
          <div className="mb-4 flex w-full items-center justify-center">
            <Link href={root_path}>
              <img src={logo} alt="logo" className="hover:animate-spin" />
            </Link>
          </div>

          <div className="mb-4 flex w-full flex-col">
            <h1 className="text-2xl font-bold">{t["Reset your password"]}</h1>
          </div>

          <form onSubmit={handleSubmit} className="text-start">
            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password">{t["Enter your new password"]}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password && (
                <div className="text-xs text-destructive">
                  {errors.password}
                </div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password_confirmation">
                {t["Confirm your new password"]}
              </Label>
              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password_confirmation &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password_confirmation && (
                <div className="text-xs text-destructive">
                  {errors.password_confirmation}
                </div>
              )}
            </div>

            <div>
              <Button
                type="submit"
                disabled={processing}
                className="btn--black"
              >
                {processing ? (
                  <>
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>{" "}
                  </>
                ) : (
                  t["Save changes"]
                )}
              </Button>
            </div>
          </form>
        </div>

        <div className="-my-4 mb-0 w-72 rounded-xl bg-slate-100 pt-4 xs:w-[25rem]">
          <div className="flex justify-between px-8 py-4">
            <p className="w-fit text-wrap text-sm text-slate-400">
              {t["If you have any questions, please contact us at"]}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPasswordReset;
