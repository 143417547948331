import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@/hooks/use-media-query";
import { usePage } from "@inertiajs/react";

import Romantics from "@/contents/romantics.mdx";

function PopUp({ children, className }) {
  const isDesktop = useMediaQuery("(min-width: 768px)"); // md: 640px

  return (
    <>
      {isDesktop ? (
        <Dialog>
          <DialogTrigger className={cn("", className)}>
            {children}
          </DialogTrigger>

          <DialogContent>
            <DialogHeader>
              <DialogTitle>致谢</DialogTitle>
              <DialogDescription>
                Congratulations on finding the Easter egg! 🥚
              </DialogDescription>
            </DialogHeader>

            <ScrollArea className="prose h-72">
              <Romantics />
            </ScrollArea>

            {/* <DialogFooter>Also something here</DialogFooter> */}
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer shouldScaleBackground>
          <DrawerTrigger className={cn("", className)}>
            {children}
          </DrawerTrigger>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>致谢</DrawerTitle>
              <DrawerDescription>
                Congratulations on finding the Easter egg! 🥚
              </DrawerDescription>
            </DrawerHeader>

            <ScrollArea className="prose mx-4 mb-4 h-72">
              <Romantics />
            </ScrollArea>

            {/* <DrawerFooter>Also something here</DrawerFooter> */}
            <DrawerClose />
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default function Footer() {
  return (
    <footer className="my-8">
      <p className="text-sm">
        <PopUp className="group inline-block underline-offset-4 hover:underline hover:decoration-dotted">
          Made with{" "}
          <span className="group-hover:animate-pulse group-hover:text-red-500">
            ❤
          </span>
          . For those Romantics.
        </PopUp>{" "}
        | <code>{usePage().version}</code>
      </p>
    </footer>
  );
}
