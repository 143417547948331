import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "@/components/EmblaCarouselArrowButtons";
import { InputFile } from "@/components/InputFile";
import useEmblaCarousel from "embla-carousel-react";
import "@/stylesheets/embla.css";

import ArrowSVG from "@/assets/arrow.svg";

import { Link, usePage } from "@inertiajs/react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import SharedPageProps from "@/types/SharedPageProps";
import { useRef } from "react";
import ButtonLoadingState from "./ButtonLoadingState";

const OPTIONS = {};
const SLIDE_COUNT = 3;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const EmblaCarousel = (props) => {
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const { paths } = usePage<SharedPageProps>().props;

  const { disabled, formHelpers } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const {
    data: formData,
    setData: setFormData,
    post,
    processing,
    progress,
    errors,
  } = formHelpers;

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const handleSubmit = (e) => {
    e.preventDefault();
    post(paths.live_photos_path, {
      // preserveScroll: true,
      onSuccess: () => {
        emblaApi.scrollTo(0);
      },
    });
    // console.log("cleaning...");
    imageInputRef.current.reset();
    videoInputRef.current.reset();
    setFormData({
      title: "default title",
      image: null,
      video: null,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <input
        type="text"
        id="title"
        name="title"
        defaultValue={formData.title}
        required
        autoComplete="off"
        // placeholder={"请输入文件名"}
        className="hidden"
      />

      <section className="embla max-w-sm">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            <div id="step-1" className="embla__slide -z-20">
              <h1 className="font-extrabold">上传静态图片</h1>
              <InputFile
                ref={imageInputRef}
                type="image"
                id="image"
                onNextButtonClick={onNextButtonClick}
                formData={formData}
                setFormData={setFormData}
                progress={progress}
              />
              <h1 className="absolute right-4 -z-10 text-9xl font-extrabold text-slate-100">
                1
              </h1>
            </div>

            <div id="step-2" className="embla__slide -z-20">
              <h1 className="font-extrabold">上传动态视频</h1>
              <InputFile
                ref={videoInputRef}
                type="video"
                id="video"
                onNextButtonClick={onNextButtonClick}
                formData={formData}
                setFormData={setFormData}
                progress={progress}
              />
              <h1 className="absolute right-4 -z-10 text-9xl font-extrabold text-slate-100">
                2
              </h1>
            </div>

            <div id="step-3" className="embla__slide -z-20">
              {/* svg arrow here, pointing up */}
              <img
                src={ArrowSVG}
                alt="arrow"
                className="absolute -top-4 right-16 rotate-[45deg] scale-x-[-1]"
              />

              <h1 className="font-extrabold">预览是否满意？</h1>

              {disabled ? (
                <>
                  <Button
                    className="btn--black w-fit"
                    onClick={() => alert("YES!")}
                    disabled={disabled}
                  >
                    是，保存！
                  </Button>
                  <span className="text-start text-xs">
                    如果您已收到邀请，请
                    <Link
                      href={paths.sign_in_path}
                      className="text-blue-700 after:content-['_↗'] hover:underline"
                    >
                      登陆
                    </Link>
                    。
                  </span>
                </>
              ) : (
                // Enabled state
                <div className="flex w-fit flex-col items-start gap-2">
                  <Button
                    className="btn--black w-fit"
                    type="submit"
                    disabled={processing}
                    onClick={(e) => {
                      if (!formData.image) {
                        onPrevButtonClick();
                        onPrevButtonClick();
                        return;
                      }

                      if (!formData.video) {
                        onPrevButtonClick();
                        return;
                      }
                    }}
                  >
                    <ButtonLoadingState processing={processing}>
                      是，保存！
                    </ButtonLoadingState>
                  </Button>
                </div>
              )}

              <h1 className="absolute right-4 -z-10 text-9xl font-extrabold text-slate-100">
                3
              </h1>
            </div>
          </div>
        </div>

        <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>
        </div>
      </section>
    </form>
  );
};

export default EmblaCarousel;
