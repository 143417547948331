import { User } from "lucide-react";
import Marquee from "react-fast-marquee";

const usernames = [
  "@笑福亭鹤瓶的波子汽水",
  "@小七呆呆",
  "@月夜moonight",
  "@HANCULX",
  "@WiLDFRUiT LABO",
  "@豆子日记",
  "@银子",
  "@Miho_Mao",
  "@马甜甜",
  "@喵言碎语的猫子",
];

export default function AlphaUsersMarquee() {
  return (
    <>
      <Marquee
        pauseOnClick
        pauseOnHover
        speed={15}
        gradient={true}
        gradientWidth={50}
        onCycleComplete={
          // Shuffle the usernames array
          () => {
            usernames.sort(() => Math.random() - 0.5);
          }
        }
      >
        <div>{usernames.join(" ")}</div>
        {/* <div className="flex">
          {usernames.map((username) => (
            <UserCard key={username} username={username} />
          ))}
        </div> */}
      </Marquee>
    </>
  );
}

function UserCard({ username }) {
  return (
    <div className="mx-2">
      <p>{username}</p>
    </div>
  );
}
