import { Link, usePage } from "@inertiajs/react";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import { useEffect } from "react";
import SharedPageProps from "@/types/SharedPageProps";

function showprops() {
  // Select the div element with id "app"
  const appDiv = document.getElementById("app");

  if (appDiv) {
    // Get the value of the data-page attribute
    const dataPageAttr = appDiv.getAttribute("data-page");

    // Decode the HTML entities
    const decodedDataPage = dataPageAttr.replace(/&quot;/g, '"');

    // Beautify the JSON string
    const beautifiedDataPage = JSON.stringify(
      JSON.parse(decodedDataPage),
      null,
      2,
    );

    console.log(beautifiedDataPage);
  }
}

export default function Layout({ children }) {
  const { flash } = usePage<SharedPageProps>().props;

  const currentTime = new Date().getTime();

  useEffect(() => {
    showprops();

    if (flash.success) {
      toast.success(flash.success);
      flash.success = null;
    }

    if (flash.warning) {
      toast.warning(flash.warning);
      flash.warning = null;
    }

    if (flash.notice) {
      toast.info(flash.notice);
      flash.notice = null;
    }

    if (flash.alert) {
      toast.error(flash.alert);
      flash.alert = null;
    }
  }, [flash, currentTime]);

  return (
    <>
      {children}
      <Toaster closeButton richColors position="top-center" theme="light" />
    </>
  );
}
