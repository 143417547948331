import logo from "@/assets/logo.svg";

import { useEffect, useState } from "react";
import { usePage, Link, router, useForm } from "@inertiajs/react";

import SharedPageProps from "@/types/SharedPageProps";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import ButtonLoadingState from "@/components/ButtonLoadingState";

const SignInForm = ({ t }: any) => {
  const {
    paths: { root_path, sign_up_path, new_identity_password_reset_path },
  } = usePage<SharedPageProps>().props;

  const {
    data: formData,
    setData: setFormData,
    post,
    processing,
    errors,
  } = useForm({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    post("/sign_in");
  };

  return (
    <>
      <div className="flex h-fit max-w-md flex-col rounded-xl border shadow-lg">
        <div className="relative z-10 w-72 rounded-xl bg-white p-8 shadow-sm xs:w-[25rem]">
          <div className="mb-4 flex w-full items-center justify-center">
            <Link href={root_path}>
              <img src={logo} alt="logo" className="hover:animate-spin" />
            </Link>
          </div>

          <div className="mb-4 flex w-full flex-col">
            <h1 className="text-2xl font-bold">{t["Sign In"]}</h1>
            <p>{t["Welcome back"]}</p>
          </div>

          <form onSubmit={handleSubmit} className="text-start">
            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="email">{t["Email"]}</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                autoFocus
                autoComplete="email"
                placeholder={t["Email"]}
                className=""
              />
              {errors.email && (
                <div className="text-xs text-red-500">{errors.email}</div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password">{t["Password"]}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="current-password"
                placeholder={t["Password"]}
              />
              {errors.password && (
                <div className="text-xs text-red-500">{errors.password}</div>
              )}
            </div>
            <div>
              <Button className="btn--black" disabled={processing}>
                <ButtonLoadingState processing={processing}>
                  {t["Sign In"]}
                </ButtonLoadingState>
              </Button>
            </div>
          </form>
        </div>

        <div className="-my-4 mb-0 rounded-xl bg-slate-100 pt-4">
          <div className="flex justify-between px-8 py-4">
            <div className="flex">
              <p className="text-sm text-slate-400">
                {t["Don't have an account?"]}
              </p>
              <Link
                href={sign_up_path}
                className="text-sm text-blue-500 hover:underline"
              >
                {t["Sign Up"]}
              </Link>
            </div>

            <Link
              href={new_identity_password_reset_path}
              className="text-sm text-blue-500 hover:underline"
            >
              {t["Forgot your password?"]}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
