import signature from "@/assets/sig.svg";

大家好。我是青城。制作 Live 图的工具已经完成开发准备上线。

但是还没有经过压力测试，所以今天开始向 minä 群的朋友们开放 Alpha 阶段测试！

### 参加 Alpha 阶段测试的要求

当前承载能力有限、只能给比较少的人开始用，如果你 -

1.  「确定」会「持续」使用这个工具来制作 Live 图、用于发小红书📕的笔记；
2.  自己有兴趣参与测试；
3.  理解可能会出现的问题（见下文）

**请去群聊中长按消息，并点一个爱心。**

我会私戳你给你发邀请码&具体细节。

### Alpha 阶段可能出现的问题

- 😭 服务器可能突然挂了
- 🤯 上传的图片和视频有可能没办法处理
- 🥶 流程突然卡住
- 😶‍🌫️ 没有客服、得手动报 Bug
- 👀 … 等等等等（更多「惊喜」等你解锁 💣💥）

### Alpha 参与者的额外福利

1.  在这个工具的寿命里免费使用（关于未来可能的「付费」这件事这里要解释一下：我们目前没有用这个工具盈利的打算。所以如果需要「付费」的话也只是为了解决未来的两个大开销——算力 & 生成图片的存储（这两个得用户自己承担，总不能我们除了开发工具还得一直倒贴成本😂）。所以可能的定价模式会比较像「100张图1块钱」这种。）所以对于 Alpha 测试者，可以这么理解：我们把你未来的使用成本包了。
2.  从 Beta 阶段你可以邀请朋友加入。
3.  永久的优先反馈通道和新功能预览体验。

最后，感谢这一个月的耐心等待，欢迎你上车！

<div className="signature">
  <figure className="m-0">
    <img src={signature} alt="青城" width="206" height="74" />
  </figure>
  <dl className="m-0">
    <dt className="m-0 font-sans font-bold">青城</dt>
    <dd className="m-0 p-0">
      <a href="mailto:creator@makelive.photos">creator@makelive.photos</a>
    </dd>
  </dl>
</div>
