import logo from "@/assets/logo.svg";

import LivePhotosCreator from "@/components/LivePhotosCreator";
import { TailwindIndicator } from "@/components/TailwindIndictor";
import Footer from "@/components/layouts/Footer";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import PrivateAlpha from "@/contents/private-alpha.mdx";
import PublicBeta from "@/contents/public-beta.mdx";

export default function Index() {
  return (
    <>
      {/* <TailwindIndicator /> */}

      <div className="absolute inset-x-0 top-0 -z-10 h-[37.5rem] bg-top bg-grid-slate-900/[0.04] [mask-image:linear-gradient(0deg,transparent,black)] xl:top-8"></div>

      <div className="mx-8 flex flex-col items-center md:my-16 md:max-w-4xl md:flex-row md:gap-16">
        <div className="my-16 space-y-4">
          <div className="mb-4 flex w-full items-center justify-center">
            <img
              src={logo}
              alt="logo"
              className="h-36 w-36 hover:animate-spin"
            />
          </div>
          <a
            href="#private-alpha"
            className="mx-auto w-fit rounded-full border border-slate-300 bg-slate-50 px-4 py-1 text-sm"
          >
            Public Beta
          </a>
          <h1 className="max-w-sm text-7xl font-bold">Make Live Photos</h1>
          <h2 className="text-2xl font-medium">制作你的 Live 图</h2>
        </div>

        <LivePhotosCreator creatorDisabled={true} />
      </div>

      <div className="mx-8 md:max-w-4xl">
        <h2 className="my-8 text-4xl font-bold">创作者的话</h2>

        <article className="prose mb-8 text-start" id="public-beta">
          <PublicBeta />
        </article>

        <hr className="mx-auto border-dashed" />

        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="private-alpha" id="private-alpha">
            <AccordionTrigger>Private Alpha 开放通知</AccordionTrigger>
            <AccordionContent>
              <article className="prose text-start">
                <PrivateAlpha />
              </article>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <Footer />
    </>
  );
}
