import logo from "@/assets/logo.svg";

import { usePage, Link } from "@inertiajs/react";
import SharedPageProps from "@/types/SharedPageProps";

const OtherErrorPage = ({ user, t }: any) => {
  const {
    paths: { root_path },
  } = usePage<SharedPageProps>().props;

  return (
    <>
      <div className="mx-8 flex h-fit flex-col">
        <div className="mb-4 flex w-full items-center justify-center gap-2">
          <Link href={root_path} className="space-x-2">
            <img
              src={logo}
              alt="logo"
              className="inline-block w-28 hover:animate-spin"
            />
            <img
              src={logo}
              alt="logo"
              className="inline-block w-28 hover:animate-spin"
            />
          </Link>

          <h1 className="text-9xl font-extrabold">PS!</h1>
        </div>

        <div className="mb-4 flex w-full flex-col">
          <h1 className="text-2xl font-bold">
            There was an error processing your request.
          </h1>
          <p>
            Take a deep breath, check the developer console, and report the
            error if necessary.
          </p>
        </div>
      </div>
    </>
  );
};

export default OtherErrorPage;
