import { useEffect, useRef } from "react";
import * as LivePhotosKit from "livephotoskit";

const LivePhotosContainer = ({
  photoSrc,
  videoSrc,
  effectType = "live",
  playbackStyle = "full",
  style,
}) => {
  const livePhotoRef = useRef(null);

  useEffect(() => {
    if (LivePhotosKit && livePhotoRef.current) {
      const player = LivePhotosKit.createPlayer({
        photoSrc,
        videoSrc,
        effectType,
        playbackStyle,
      });

      player.style =
        "position: relative; display: inline-block;" + (style ? style : "");

      livePhotoRef.current.innerHTML = ""; // Clear any existing content
      // Append the player to the container
      livePhotoRef.current.appendChild(player);
    }
  }, [photoSrc, videoSrc, effectType, playbackStyle, style]);

  return <div id="live-photo-container" ref={livePhotoRef} />;
};

export default LivePhotosContainer;
