import logo from "@/assets/logo.svg";

import { usePage, Link, useForm } from "@inertiajs/react";
import SharedPageProps from "@/types/SharedPageProps";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import ButtonLoadingState from "@/components/ButtonLoadingState";

const SignUpForm = ({ user, t }: any) => {
  const {
    paths: { root_path, sign_in_path, sign_up_path },
  } = usePage<SharedPageProps>().props;

  const {
    data: formData,
    setData: setFormData,
    post,
    processing,
    errors,
  } = useForm({
    email: "",
    password: "",
    password_confirmation: "",
    user_name: "",
    invitation_code: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    post(sign_up_path);
    // toast.warning(t["Registration Disabled"]);
  };

  return (
    <>
      <div className="flex h-fit max-w-md flex-col rounded-xl border shadow-lg">
        <div className="relative z-10 w-72 rounded-xl bg-white p-8 shadow-sm xs:w-[25rem]">
          <div className="mb-4 flex w-full items-center justify-center">
            <Link href={root_path}>
              <img src={logo} alt="logo" className="hover:animate-spin" />
            </Link>
          </div>

          <div className="mb-4 flex w-full flex-col">
            <h1 className="text-2xl font-bold">{t["Sign Up"]}</h1>
            <p>{t["Welcome to MakeLivePhotos"]}</p>
          </div>

          <form onSubmit={handleSubmit} className="text-start">
            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="email">{t["Email"]}</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                autoFocus
                autoComplete="email"
                placeholder={user.email}
                className={
                  errors.email &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.email && (
                <div className="text-xs text-destructive">{errors.email}</div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password">{t["Password"]}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password && (
                <div className="text-xs text-destructive">
                  {errors.password}
                </div>
              )}
            </div>

            <div className="mb-4 grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="password_confirmation">
                {t["Password Confirmation"]}
              </Label>
              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
                autoComplete="new-password"
                placeholder={t["12 characters minimum"]}
                className={
                  errors.password_confirmation &&
                  "border-destructive focus-visible:ring-destructive"
                }
              />
              {errors.password_confirmation && (
                <div className="text-xs text-destructive">
                  {errors.password_confirmation}
                </div>
              )}
            </div>

            <div className="mb-4 flex flex-row gap-4">
              <div className="grid h-full w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="user_name">{t["User Name"]}</Label>
                <Input
                  type="text"
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  required
                  autoComplete="name"
                  placeholder={t["User Name"]}
                  className={
                    errors.user_name &&
                    "border-destructive focus-visible:ring-destructive"
                  }
                />
                {errors.user_name && (
                  <div className="text-xs text-destructive">
                    {errors.user_name}
                  </div>
                )}
              </div>

              <div className="grid h-full w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="invitation_code">{t["Invitation Code"]}</Label>
                <Input
                  type="text"
                  id="invitation_code"
                  name="invitation_code"
                  value={formData.invitation_code}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  placeholder={t["Invitation Code"]}
                  className={
                    errors.invitation_code &&
                    "border-destructive focus-visible:ring-destructive"
                  }
                />
                {errors.invitation_code && (
                  <div className="text-xs text-destructive">
                    {errors.invitation_code}
                  </div>
                )}
              </div>
            </div>

            <div className="text-center">
              <Button
                type="submit"
                disabled={processing}
                className="btn--black"
              >
                <ButtonLoadingState processing={processing}>
                  {t["Sign Up"]}
                </ButtonLoadingState>
              </Button>
              <p className="mt-2 text-sm text-slate-400">
                {t["Registration Disabled"]}
              </p>
            </div>
          </form>
        </div>

        <div className="-my-4 mb-0 rounded-xl bg-slate-100 pt-4">
          <div className="flex justify-between px-8 py-4">
            <div className="flex">
              <p className="text-sm text-slate-400">
                {t["Already have an account?"]}
              </p>
              <Link
                href={sign_in_path}
                className="text-sm text-blue-500 hover:underline"
              >
                {t["Sign In"]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
