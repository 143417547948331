"use strict";

import LivePhotosContainer from "./LivePhotosContainer";

export default function LivePhotoAlbum({ photoSrc, videoSrc }) {
  return (
    <>
      <LivePhotosContainer
        photoSrc={photoSrc}
        videoSrc={videoSrc}
        style="width: 100%; height: 100%;"
        effectType="live"
        playbackStyle="full"
      />
    </>
  );
}

