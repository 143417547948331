import indexPhoto from "@/assets/5.jpg";
import indexVideo from "@/assets/5.mov";
import signature from "@/assets/sig.svg";

import LivePhotoAlbum from "@/components/LivePhotoAlbum";
import EmblaCarousel from "@/components/EmblaCarousel";

import { cn } from "@/lib/utils";
import { useForm } from "@inertiajs/react";

interface LivePhotosCreatorProps {
  creatorDisabled: boolean;
  className?: string;
}

const LivePhotosCreator = (props: LivePhotosCreatorProps) => {
  const { creatorDisabled, className } = props;

  const formHelpers = useForm({
    title: "default title",
    image: null,
    video: null,
  });

  const {
    data: formData,
    setData: setFormData,
    post,
    progress,
    processing,
    errors,
  } = formHelpers;

  const { image, video } = formData;

  return (
    <div
      className={cn(
        className,
        "h-fit rounded-2xl border border-slate-200 bg-slate-50/25 shadow-sm",
      )}
    >
      <div className="whitespace-no-wrap group flex h-[2.438rem] select-none items-center border-b border-b-slate-200 px-3">
        <span className="mx-1 inline-block h-3 w-3 rounded-full bg-slate-300 group-hover:bg-red-500"></span>
        <span className="mx-1 inline-block h-3 w-3 rounded-full bg-slate-300 group-hover:bg-yellow-500"></span>
        <span className="mx-1 inline-block h-3 w-3 rounded-full bg-slate-300 group-hover:bg-green-500"></span>
      </div>

      <div className="my-8 flex flex-col items-center gap-8">
        <div id="album" className="aspect-square w-full">
          {image && video ? (
            <LivePhotoAlbum
              photoSrc={URL.createObjectURL(image)}
              videoSrc={URL.createObjectURL(video)}
            />
          ) : (
            <>
              <div className="flex aspect-square w-full items-center justify-center font-extrabold">
                请上传照片和视频后预览效果
              </div>
              <p className="text-sm"></p>
            </>
          )}
        </div>

        <EmblaCarousel disabled={creatorDisabled} formHelpers={formHelpers} />
      </div>
    </div>
  );
};

export default LivePhotosCreator;
