import { Input } from "@/components/ui/input";
import { Progress } from "@/components/ui/progress";
import { useRef, forwardRef, useImperativeHandle } from "react";

const InputFile = forwardRef(
  ({ type, id, onNextButtonClick, formData, setFormData, progress }, ref) => {
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      reset() {
        fileInputRef.current.value = null;
      },
    }));

    let accept_type = "";
    if (type === "image") {
      // [".jpg", ".jpeg", ".heic", ".heif"]
      accept_type = "image/jpeg, image/jpg, image/heic, image/heif";
    } else if (type === "video") {
      // [".mov", ".mp4"]
      accept_type = "video/mov, video/mp4";
    }

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      const file = files[0];

      if (type === "image") {
        const title = file.name.split(".")[0];
        setFormData((prevState) => ({
          ...prevState,
          title,
        }));
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
      }));

      onNextButtonClick();
    };

    return (
      <div className="grid w-full max-w-sm items-center gap-1.5">
        {progress && <Progress value={progress.percentage} />}

        <Input
          type="file"
          id={type}
          name={type}
          onChange={handleFileChange}
          accept={accept_type}
          required
          ref={fileInputRef}
        />
      </div>
    );
  },
);

export { InputFile };
