import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { toast } from "sonner";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardDescription,
  CardTitle,
} from "@/components/ui/card";
import SharedPageProps from "@/types/SharedPageProps";
import { Link, router, usePage } from "@inertiajs/react";
import { ClipboardCopyIcon } from "lucide-react";

interface InvitationProps {
  id: number;
  code: string;
  code_is_used: boolean;
  created_at: string;
  updated_at: string;
  inviter: {
    user_name: string;
    email: string;
  };
  invitee?: {
    user_name: string;
    email: string;
  };
}

interface InvitationListProps {
  t: any;
  invitations_quota_left: number | "∞";
  invitations: InvitationProps[];
}

export default function Index({
  t,
  invitations_quota_left,
  invitations,
}: InvitationListProps) {
  const {
    user: { status },
    paths: { invitation_codes_path, home_path },
  } = usePage<SharedPageProps>().props;

  const handleClick = (e: any) => {
    e.preventDefault();
    router.post(invitation_codes_path);
  };

  invitations_quota_left =
    status === "super_admin" || status === "admin" || status === "private_alpha"
      ? "∞"
      : invitations_quota_left;

  return (
    <>
      <div className="container mx-auto p-4 text-start">
        <div className="flex items-center justify-between">
          <h1 className="mb-4 text-2xl font-bold">{t["Invitation Codes"]}</h1>
          <p className="text-xs text-muted-foreground">
            {t["Your invitation codes quota left"]}
            {invitations_quota_left}
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {!invitations ||
            (invitations.length === 0 && (
              <>
                <Card className="col-span-full row-span-full border-dashed">
                  <CardHeader data-card="header">
                    <CardTitle data-card="title">No Invitation Codes</CardTitle>
                    <CardDescription>
                      You have not created any invitation codes yet. Click the
                      button below to create one.
                    </CardDescription>
                  </CardHeader>
                </Card>
              </>
            ))}

          {invitations.map((invitation) => (
            <Card key={invitation.id} className="shadow-md">
              <CardHeader data-card="header">
                <CardTitle
                  data-card="title"
                  className="group flex items-center justify-between"
                >
                  <code
                    className={
                      invitation.code_is_used
                        ? "line-through hover:cursor-not-allowed"
                        : "flex items-center hover:cursor-pointer"
                    }
                    onClick={
                      invitation.code_is_used
                        ? undefined
                        : () => {
                            navigator.clipboard.writeText(invitation.code);
                            toast.success("Copied to clipboard");
                          }
                    }
                  >
                    {invitation.code}

                    {invitation.code_is_used ? (
                      <span className="ml-2 text-xs">[{t["Used"]}]</span>
                    ) : (
                      <ClipboardCopyIcon className="-ml-4 h-4 w-4 stroke-transparent transition-all ease-in-out group-hover:translate-x-4 group-hover:stroke-foreground" />
                    )}
                  </code>

                  <Badge
                    variant="outline"
                    className={
                      invitation.code_is_used
                        ? "border-red-500 text-red-500"
                        : "border-green-500 text-green-500"
                    }
                  >
                    {invitation.code_is_used ? t["Used"] : t["Available"]}
                  </Badge>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="mb-2">
                  <p className="font-semibold">{t["Inviter"]}:</p>
                  <span>
                    {invitation.inviter.user_name} ({invitation.inviter.email})
                  </span>
                </div>
                {invitation.invitee && (
                  <div className="mb-2">
                    <p className="font-semibold">{t["Invitee"]}:</p>
                    <span>
                      {invitation.invitee.user_name} ({invitation.invitee.email}
                      )
                    </span>
                  </div>
                )}
              </CardContent>
              <CardFooter
                data-card="footer"
                className="flex flex-row justify-between text-xs uppercase"
              >
                <p className="flex flex-col">
                  <span className="font-semibold">{t["Created at"]}</span>
                  {new Date(invitation.created_at).toLocaleString()}
                </p>
                <p className="flex flex-col">
                  <span className="font-semibold">{t["Last Updated at"]}</span>
                  {new Date(invitation.updated_at).toLocaleString()}
                </p>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center space-y-2">
        <Button
          onClick={handleClick}
          disabled={
            invitations_quota_left !== "∞" && invitations_quota_left <= 0
          }
        >
          {t["Create Invitation Code"]}
        </Button>

        <div className="flex gap-1 font-medium">
          <p className="text-sm text-slate-700">{t["Never mind"]}</p>
          <Link
            href={home_path}
            className="text-sm text-slate-500 hover:underline"
          >
            {t["Back home"]}
          </Link>
        </div>
      </div>
    </>
  );
}
