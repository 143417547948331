import { Link } from "@inertiajs/react";

export default function Index({ sessions }) {
  return (
    <>
      {sessions.map((session) => (
        <div
          key={session.id}
          className="mb-4 rounded-md border border-gray-200 p-4"
        >
          <div className="flex flex-col items-center justify-between">
            <p>
              <strong> User Agent </strong>
              {session.user_agent}
            </p>
            <p>
              <strong> IP Address </strong>
              {session.ip_address}
            </p>
            <p>
              <strong> Created At </strong>
              {
                // ISO 8601 format
                new Date(Date.parse(session.created_at)).toLocaleString()
              }
            </p>

            <Link
              href={`/sessions/${session.id}`}
              method="delete"
              className="text-blue-500 hover:underline"
              as="button"
              type="button"
            >
              Log out
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}
